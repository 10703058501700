import React from 'react'; 

export default function FiberArtGallery() {
    return (
        <div class="container-fluid p-5">
            <h1>Fiber Arts</h1>
            <p>Probably my biggest hobby at the moment is knitting. I also dabble in crochet.</p>
            <p>Why Knitting is Like Coding</p>
            <p>Although this is largely just a fun thing that I wanted to display on my website, I wanted to discuss why I think that 
                knitting is similar to coding in several ways.
            </p>
            <p>(This is a WIP will update later on).</p>
        </div>
    );
}